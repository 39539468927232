<template>
  <div class="page-wrapper section-space--inner--40 section-space--bottom--120">
    <!--Contact section start-->
    <div class="conact-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="contact-information">
              <h3>{{ data.contactInfo.title }}</h3>
              <ul>
                <li>
                  <span class="icon">
                    <i :class="data.contactInfo.address.icon"></i>
                  </span>
                  <span class="text">
                    {{ data.contactInfo.address.location }}
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <i :class="data.contactInfo.telephone.icon"></i>
                  </span>
                  <span class="text">
                    <a href="tel:0271625252">{{
                      data.contactInfo.telephone.no_1
                    }}</a>
                    <a href="https://wa.me/6285853527441" target="_blank">{{
                      data.contactInfo.telephone.no_2
                    }}</a>
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <i class="fa fa-clock-o"></i>
                  </span>
                  <span> 08.00 - 17.00 WIB </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15819.112752310504!2d110.8116531!3d-7.5991024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5223a52eb9fa3d6!2sPutra%20Ramayana.%20CV!5e0!3m2!1sen!2sid!4v1640079815128!5m2!1sen!2sid"
              style="width: 100%; height: 300px; border: 1px"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--Contact section end-->
  </div>
</template>

<script>
import data from "../../data/contact.json";
export default {
  data() {
    return {
      data,
    };
  },
};
</script>
