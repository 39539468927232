<template>
  <div class="page-wrapper section-space--inner--100">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 mb-3" style="margin: auto">
          <h3>{{ getHoursCondition }}, Pelanggan Setia</h3>
          <h3>Apa yang bisa kami bantu?</h3>
        </div>
        <div class="col-lg-6 col-12" style="margin: auto">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-mobile">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="info">{{
                  title_1
                }}</b-button>
              </b-card-header>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>{{ text_1 }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 card-mobile">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="info">{{
                  title_2
                }}</b-button>
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>{{ text_2 }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 card-mobile">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="info">{{
                  title_3
                }}</b-button>
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>{{ text_3 }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text_1: `Pembayaran Hanya Melalui Rekening BCA 7850238558 atau 7735090101 a/n CV.Putra Ramayana`,
      text_2: `Informasi Pembelian Grosir atau Ecer dapat menghubungi 0858-5352-7441 (Whatsapp) setiap Senin-Sabtu Jam 08.00-17.00 WIB`,
      text_3: `Kami dapat menjangkau dan melayani pembelian dari seluruh daerah di Indonesia sesuai syarat dan ketentuan yang berlaku`,
      title_1: `Informasi Pembayaran`,
      title_2: `Informasi Pembelian Grosir / Ecer`,
      title_3: `Informasi Jangkauan Penjualan`,
      hours: new Date().getHours(),
      getHoursCondition: "",
    };
    ``;
  },
  methods: {
    getHours() {
      if (this.hours > 24 && this.hours <= 10) {
        this.getHoursCondition = "Selamat Pagi";
      } else if (this.hours > 10 && this.hours <= 15) {
        this.getHoursCondition = "Selamat Siang";
      } else if (this.hours > 15 && this.hours <= 18) {
        this.getHoursCondition = "Selamat Sore";
      } else if (this.hours > 18 && this.hours <= 24) {
        this.getHoursCondition = "Selamat Malam";
      }
    },
  },
  mounted() {
    this.getHours();
  },
};
</script>

<style>
.btn-info {
  background-color: red;
  border-color: red;
}

.btn-info:hover {
  background-color: red;
  border-color: red;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #fb030e;
  border-color: #fb030e;
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #fb030e;
  border-color: #fb030e;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(251 3 14 / 50%);
  box-shadow: 0 0 0 0.2rem rgb(251 3 14 / 50%);
}
</style>
