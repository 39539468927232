<template>
  <div class="main-container">
    <Header />

    <FAQ />

    <ContactMainWrapper />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import ContactMainWrapper from "../components/sections/ContactMainWrapper";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import FAQ from "../components/FAQ.vue";

export default {
  components: {
    Header,
    FAQ,
    ContactMainWrapper,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
  },
  data() {
    return {
      items: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Contact",
          active: true,
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Contact Us",
      titleTemplate:
        "%s - CV. Putra Ramayana Grosir Ban, Oli, Aki, dan Sparepart Solo",
      meta: [
        {
          name: "keywords",
          content: "CV Putra Ramayana Solo",
        },
        {
          property: "og:keyword",
          content: "CV Putra Ramayana",
        },
        {
          name: "og:description",
          content:
            "Kontak Kami Untuk Informasi Produk Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        {
          property: "og:title",
          content:
            this.$route.params.kategori_produk +
            " - CV Putra Ramayana " +
            "Grosir Ban Oli Aki Sparepart Solo Murah",
        },
        {
          property: "og:site_name",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:viewport",
          content: "width=device-width, initial-scale=1",
        },

        // TWITTER META
        { name: "twitter:card", content: "website" },
        {
          name: "twitter:site",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        { name: "twitter:title", content: "Home - CV Putra Ramayana" },
        {
          name: "twitter:description",
          content:
            "CV Putra Ramayana Tempat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        // Your twitter handle, if you have one.
        { name: "twitter:creator", content: "@cv_putraramayana" },
        {
          name: "twitter:image:src",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },

        // Google / Schema.org markup:
        { itemprop: "name", content: "Home - CV Putra Ramayana" },
        {
          itemprop: "description",
          content:
            "CV Putra Ramayana Tempat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        {
          itemprop: "image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },

        { name: "robots", content: "index,follow" },

        { name: "robots", content: "index,follow" },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
};
</script>
