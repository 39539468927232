<template>
  <!--====================  brand logo area ====================-->
  <div class="brand-logo-slider-area" :class="addClass">
    <div class="container">
      <div class="title-brand text-center">
        <h1>CV. Putra Ramayana</h1>
        <p>Melayani penjualan Sparepart Roda 2, Roda 4, dan Power Tools</p>
      </div>

      <!-- brand logo slider -->
      <div class="brand-logo-slider__container-area">
        <div class="swiper-container brand-logo-slider__container">
          <div class="swiper-wrapper brand-logo-slider__wrapper">
            <swiper :options="swiperOption">
              <div
                class="swiper-slide brand-logo-slider__single"
                v-for="brand in brands"
                :key="brand.id_brand"
              >
                <div class="image text-center brand-size">
                  <a href="#">
                    <img :src="brand.url_gambar_brand" alt="brand logo" />
                  </a>
                </div>
              </div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of brand logo area  ====================-->
</template>

<script>
import axios from "axios";
export default {
  props: ["addClass"],
  data() {
    return {
      brands: [],
      swiperOption: {
        loop: true,
        speed: 1000,
        watchSlidesVisibility: true,
        CenteredSlides: true,
        spaceBetween: 30,
        autoplay: {
          delay: 1000,
        },
        // Responsive breakpoints
        breakpoints: {
          1499: {
            slidesPerView: 6,
          },
          1200: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 4,
          },

          480: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  methods: {
    getData() {
      axios
        .get("https://admin.putraramayana.com/api/brands")
        .then((response) => {
          this.brands = response.data;
          console.log(brands);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
